/** 账号设置 */
<template>
  <section class="user-info">
    <h4 v-if="$isPc" class="user-title mb-20">账号设置</h4>
    <el-row :class="['user-list',{'plr-10':!$isPc}]">
      <el-col v-if="$isPc" :span="24" class="user-item">
        <label class="user-label">用户名</label>
        <UpdateName />
      </el-col>
      <el-col :span="24" class="user-item">
        <label>手 机</label>
        <div class="user-con">
          <span>{{this.$store.getters.userInfo.phone|encryptPhone}}</span>
          <router-link to="/my/account_settings/update_phone">
            <el-button type="text" class="ml-20">修改手机</el-button>
          </router-link>

        </div>
      </el-col>
      <el-col :span="24" class="user-item">
        <label>密 码</label>
        <div class="user-con">
          <router-link to="/my/account_settings/update_pass">
            <el-button type="text" class="ml-20">修改密码</el-button>
          </router-link>
        </div>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import UpdateName from './components/updateName.vue'
export default {
  name: 'Info',
  components: {
    UpdateName
  },
}
</script>
<style lang="scss" scoped>
.user-list {
  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #e3e3e3;
    &:last-child {
      border-bottom: 0;
    }
    /deep/.el-form {
      .el-form-item {
        margin-bottom: 0;
        .el-input__inner {
          width: 96px;
          padding: 0;
          border: 0;
          border-radius: 0;
          font-size: 16px;
          color: #333;
        }
        .is-active {
          .el-input__inner {
            border-bottom: 1px solid #606266;
          }
        }
      }
    }
    /deep/.el-button--text {
      width: 66px;
      text-align: left;
      color: #409eff;
      &:hover {
        color: #66b1ff;
      }
      label {
        display: block;
        cursor: pointer;
      }
    }
  }
}
</style>